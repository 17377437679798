import React from "react"
import { observer } from "mobx-react-lite"
import groupBy from "lodash/groupBy"

import { Option } from "@framework/types/utils"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store"
import { capitalizeFirstLetter } from "@utils/textUtils"
import { solutionCategoryOptions } from "@framework/constants/solution-category"

export interface NavigationScopeContextData {
  allowedNavOptions: Option[]
  allowedProfileOptions: Option[]
  setActiveOption: (option: Option) => void
  activeOption?: Option
}

export const NavigationScopeContext =
  React.createContext<NavigationScopeContextData>({
    allowedNavOptions: [],
    allowedProfileOptions: [],
    setActiveOption: () => {},
  })

export type Props = {}

export const NavigationScopeContextProvider: React.FC<Props> = observer(
  ({ children }) => {
    const { restrictionsStore: access, solutionsStore } = useStore()
    const [activeOption, setActiveOption] = React.useState<Option>()

    const { solutions } = solutionsStore

    const allowedNavOptions = React.useMemo(() => {
      const navList: Option[] = []

      // Home
      const groupedSolutions = groupBy(solutions, "category")

      const homeSubOptions = Object.entries(groupedSolutions)
        .filter(([category]) =>
          solutionCategoryOptions.includes(category as any)
        )
        .map<Option>(([category, solutions]) => ({
          name: `${mainRoutes.home()}?category=${category}`,
          value: capitalizeFirstLetter(category),
          icon: "jigsaw-puzzle",
          subOptions: solutions.map((solution) => ({
            name: `${mainRoutes.home()}?category=${category}&solutions=${
              solution.id
            }`,
            value: capitalizeFirstLetter(solution.name),
            icon: "jigsaw-puzzle",
          })),
        }))

      navList.push({
        name: mainRoutes.home(),
        value: "Home",
        icon: "home",
        subOptions: homeSubOptions,
      })

      // Library
      if (access.canViewDataSources)
        navList.push({
          name: mainRoutes.upload(),
          value: "Data Manager",
          icon: access.canEditDataSource ? "upload-cloud" : "cloud",
        })

      // New library
      const librarySubOptions: Option[] = []

      if (access.canViewManufacturer) {
        librarySubOptions.push({
          name: mainRoutes.manufacturer(),
          value: "Manufacturer",
          icon: "cloud",
        })
      }

      if (access.canViewContentManager) {
        librarySubOptions.push({
          name: mainRoutes.contentManager(),
          value: "Content Manager",
          icon: "cloud",
        })
      }

      // Products
      if (access.canViewProducts) {
        librarySubOptions.push({
          icon: "book-shelf",
          name: mainRoutes.products(),
          value: "Products",
        })
      }

      if (librarySubOptions.length)
        navList.push({
          name: mainRoutes.library(),
          value: "Library",
          icon: "book-shelf",
          subOptions: librarySubOptions,
        })

      // Analytics
      const analyticsSubOptions: Option[] = []

      if (access.isAppUsageAnalyticsAvailable) {
        analyticsSubOptions.push({
          name: mainRoutes.usageAnalytics(),
          value: "Usage Analytics",
          icon: "stats-random",
        })
      }

      if (access.isAPIUsageAnalyticsAvailable) {
        analyticsSubOptions.push({
          name: mainRoutes.apiUsageAnalytics(),
          value: "API Usage Analytics",
          icon: "stats-random",
        })
      }

      if (analyticsSubOptions.length)
        navList.push({
          name: mainRoutes.analytics(),
          value: "Analytics",
          icon: "stats-random",
          subOptions: analyticsSubOptions,
        })

      // Experts
      const expertsSubOptions: Option[] = []

      if (access.canViewLeaderboard)
        expertsSubOptions.push({
          name: mainRoutes.experts(),
          value: "Leaderboard",
          icon: "users",
        })

      if (access.canViewExpertInsights)
        expertsSubOptions.push({
          name: mainRoutes.expertInsights(),
          value: "Insights",
          icon: "user-insight",
        })

      if (access.canViewQuestions)
        expertsSubOptions.push({
          name: mainRoutes.questions(),
          value: "Questions",
          icon: "questions-chat",
        })

      if (expertsSubOptions.length)
        navList.push({
          name: mainRoutes.expertsMenu(),
          value: "Experts",
          icon: "users",
          subOptions: expertsSubOptions,
        })

      // Latest Updates
      if (access.isLatestUpdatesAvailable)
        navList.push({
          name: mainRoutes.updates(),
          value: "Latest Updates",
          icon: "loader",
        })

      // Admin panel
      if (access.isAdminPanelAvailable) {
        const adminSubOptions: Option[] = []

        if (access.isUsersListAvailable) {
          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("users"),
            value: "Users",
            icon: "terminal-window-line",
          })
        }

        if (access.onlySuperAdminAccess) {
          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("roles"),
            value: "Roles & Actions",
            icon: "terminal-window-line",
          })

          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("features"),
            value: "Feature Flags",
            icon: "terminal-window-line",
          })

          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("config"),
            value: "Instance Config",
            icon: "terminal-window-line",
          })
        }

        if (access.isGlossaryAvailable) {
          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("glossary"),
            value: "Glossary",
            icon: "terminal-window-line",
          })
        }

        if (access.isAvatarsAvailable) {
          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("avatars"),
            value: "Avatars",
            icon: "terminal-window-line",
          })
        }

        adminSubOptions.push({
          name: mainRoutes.adminPanelTab("datatypes"),
          value: "Content Types",
          icon: "storage",
        })

        if (access.canAccessAPIKeys) {
          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("api-keys"),
            value: "Api Keys",
            icon: "terminal-window-line",
          })
        }

        if (access.isSubscriptionPageAvailable) {
          adminSubOptions.push({
            name: mainRoutes.adminPanelTab("subscription"),
            value: "Subscription",
            icon: "terminal-window-line",
          })
        }

        if (adminSubOptions.length)
          navList.push({
            name: mainRoutes.adminPanel(),
            value: "Admin panel",
            icon: "terminal-window-line",
            subOptions: adminSubOptions,
          })
      }

      return navList
    }, [access.config, solutions])

    const allowedProfileOptions = React.useMemo(() => {
      const navList: Option[] = []

      if (access.showUserProfile)
        navList.push({ value: "Settings", name: "profile", icon: "settings" })

      return navList
    }, [access.config])

    const context: NavigationScopeContextData = {
      allowedProfileOptions,
      allowedNavOptions,
      activeOption,
      setActiveOption,
    }

    return (
      <NavigationScopeContext.Provider value={context}>
        {children}
      </NavigationScopeContext.Provider>
    )
  }
)

export default NavigationScopeContextProvider
