import React from "react"
import { Routes, Route, Navigate, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import ActiveAvatarContextProvider from "@pages/search/ActiveAvatarContext"
import AvatarScopeContextProvider from "@pages/search/AvatarScopeContext"
import mainRoutes from "@root/main.routes"
import PresetEditor from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/UnifiedMatrix/PresetEditor"
import { RouteWithSolutionContextProvider } from "@pages/launch-solutions/ActiveSolutionContext"
import MainLayout from "@components/layout/MainLayout/MainLayout"

import LaunchSolutions from "../launch-solutions/LaunchSolutions"

const ProductSolutionsRoot: React.FC = observer(() => {
  const navigate = useNavigate()

  const {
    userSetupStore: { avatarId, setAvatarId },
  } = useStore()

  const { documentChatSolutionController, factFinderSolutionController } =
    useController()

  React.useEffect(() => {
    // TODO think over this logic
    documentChatSolutionController.reset()
    factFinderSolutionController.reset()
  }, [avatarId])

  const setSolution = (solutionId: string) =>
    navigate(mainRoutes.solutionView(solutionId))

  return (
    <ActiveAvatarContextProvider avatarId={avatarId}>
      <AvatarScopeContextProvider avatarId={avatarId} onChange={setAvatarId}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={mainRoutes.home()} replace />}
          />

          <Route path=":solutionId/edit" element={<PresetEditor />} />

          <Route
            path=":solutionId/*"
            element={
              <MainLayout noPadding>
                <RouteWithSolutionContextProvider
                  solutionIdPropName="solutionId"
                  onSolutionChange={setSolution}
                >
                  <LaunchSolutions />
                </RouteWithSolutionContextProvider>
              </MainLayout>
            }
          />
        </Routes>
      </AvatarScopeContextProvider>
    </ActiveAvatarContextProvider>
  )
})

export default ProductSolutionsRoot
