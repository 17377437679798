import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { useStore } from "@store"
import { QueryHistoryData } from "@framework/types/analytics"
import { LOCAL_TIME_FORMAT, US_DATE_FORMAT } from "@framework/constants/global"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import UserCard from "@components/ui/UserCard/UserCard"
import Column from "@components/ui/BaseTable/Column"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Row from "@components/ui/BaseTable/Row"
import Icon from "@components/ui/Icon/Icon"
import Chip from "@components/ui/Chip/Chip"
import Rating from "@components/ui/Rating/Rating"
import { SolutionTypes } from "@framework/constants/search-results"
import { AnswerStatus } from "@framework/types/question"
import IconButton from "@components/ui/IconButton/IconButton"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"

import styles from "./QueryHistoryTable.module.sass"

interface QueryHistoryTableRowProps {
  queryInfo: QueryHistoryData
  onAssignQuestionClick?: React.MouseEventHandler
}

const QueryHistoryTableRow: React.FC<QueryHistoryTableRowProps> = observer(
  ({ queryInfo, onAssignQuestionClick }) => {
    const {
      id,
      question,
      user,
      createdAt,
      rating,
      experts,
      comment,
      totalAnswered,
      totalAwaiting,
      channelName,
      productSolution,
      status,
      summary: answer,
    } = queryInfo

    const {
      restrictionsStore: access,
      solutionsStore: { allSolutions },
    } = useStore()
    const modal = useModal(ModalsTypes.QUERY_ANSWER_MODAL)
    const showQueryAnswerModal = () =>
      modal.showModal({
        answer,
        question,
        user,
      })

    const renderExperts = () => {
      if (experts?.length) {
        const isAnswered = Number(totalAnswered) > 0
        const isSingle = experts.length === 1

        return (
          <div>
            <UsersBubbles
              size="small"
              users={experts}
              suffix={isSingle ? experts[0].name : `${experts.length} Experts`}
              className={styles.userCard}
            />
            {isSingle ? (
              <Chip
                color={isAnswered ? "green" : "gold"}
                variant="rounded"
                uppercase
              >
                {isAnswered ? "Verified" : "Pending"}
              </Chip>
            ) : (
              <Chip
                color={isAnswered ? "green" : "gold"}
                variant="rounded"
                uppercase
              >
                {isAnswered
                  ? `${totalAnswered} Verified`
                  : `${totalAwaiting} Waiting`}
              </Chip>
            )}
          </div>
        )
      }

      if (status === AnswerStatus.WAITING) {
        return (
          <div>
            <Text variant="body2">Open question</Text>

            <Chip color="gold" variant="rounded" uppercase>
              Waiting
            </Chip>
          </div>
        )
      }

      if (!onAssignQuestionClick) return "-"

      if (
        allSolutions?.find(
          (solution) => solution.key === SolutionTypes.PrivateWorkspace
        )?.id === productSolution.id
      ) {
        return "-"
      }

      if (!access.canPostToExpert) return "-"

      return (
        <Button
          before={<Icon name="plus" />}
          onClick={onAssignQuestionClick}
          size="small"
          variant="outlined"
        >
          Assign
        </Button>
      )
    }

    return (
      <Row>
        <Column key={`${id}-question`}>
          {question}
          {answer && (
            <IconButton onClick={showQueryAnswerModal}>
              <Icon name="eye" inline />
            </IconButton>
          )}
        </Column>

        <Column key={id}>
          <UserCard
            metaInfo={user.email}
            fullName={user.name}
            avatarSrc={user.avatarUrl ?? ""}
          />
        </Column>

        <Column key={`${id}-avatar`}>{channelName}</Column>

        <Column key={`${id}-solution`}>{productSolution.name}</Column>

        <Column key={`${id}-date`}>
          <div className={styles.centeredTableRow}>
            <Text variant="inherit">
              {moment(createdAt).format(US_DATE_FORMAT)}
              <br />
              {moment(createdAt).format(LOCAL_TIME_FORMAT)}
            </Text>
          </div>
        </Column>

        <Column key={`${id}-feedback`}>
          <div className={styles.centeredTableRow}>
            <Tooltip placement="top" color="primary">
              <TooltipTrigger>
                <Rating value={rating ?? 0} className={styles.ratingWidget} />
              </TooltipTrigger>
              <TooltipContent>{comment || "No comment"}</TooltipContent>
            </Tooltip>
          </div>
        </Column>

        {access.canViewQuestions && (
          <Column key={`${id}-experts`}>{renderExperts()}</Column>
        )}
      </Row>
    )
  }
)

export default QueryHistoryTableRow
