import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DataConnectorSourceName } from "@framework/types/upload"
import { IconName } from "@components/ui/Icon/IconName"
import { DocumentIconType } from "@framework/types/utils"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import { capitalizeFirstLetter } from "@utils/textUtils"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Loader from "@components/ui/Loader/BarLoader"
import Select from "@components/ui/MultiSelect/Select"

import { ModalsTypes } from "../constants"
import SharePointConfig from "./SharePointConfig"
import {
  configFormInitialValues,
  configFormValidationSchema,
  getFormHeadingsAndOtherInfo,
} from "./ConfigFormHelper"

import styles from "./ContentManagerConfigFormModal.module.sass"

export type ContentManagerConfigFormModalProps = {
  sourceName: DataConnectorSourceName
  icon?: DocumentIconType | IconName
  label: string
}

const ContentManagerConfigFormModal: React.FC<ContentManagerConfigFormModalProps> =
  observer(({ sourceName, icon, label }) => {
    const [showSecret, setShowSecret] = useState(false)
    const alert = useAlert()

    const { hideModal, hideAllModals } = useModal(
      ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL
    )

    const { contentManagerController } = useController()
    const { contentManagerStore, allDatatypesStore } = useStore()
    const {
      datatypesController: { loadAllDataTypes },
    } = useController()

    const contentTypes = allDatatypesStore?.state?.data

    const { loadingCreatedContentSource, companies } = contentManagerStore || {}
    const { domainHeading, domainPlaceholder, isWebsite } =
      getFormHeadingsAndOtherInfo(sourceName)
    const formik = useFormik({
      initialValues: {
        ...configFormInitialValues,
        type: sourceName.toString(),
      },
      validationSchema: configFormValidationSchema,
      onSubmit: async (form) => {
        const { useCertificate, certificate, ...rest } = form

        let data: any = rest

        if (
          certificate &&
          Object.values(certificate).filter((val) => !!val).length > 0
        ) {
          data = { ...data, certificate }
        }

        const success = await (isWebsite
          ? contentManagerController.connectWebsite(rest)
          : contentManagerController.createContentSource(rest))
        if (success) {
          alert.success(
            isWebsite
              ? "Successfully connected the website it will take sometime for the process to complete"
              : "Successfully created content source"
          )
          hideAllModals()
        } else {
          alert.error("Failed to connect")
        }
      },
    })
    useEffect(() => {
      formik.validateForm()
    }, [sourceName])

    useEffect(() => {
      loadAllDataTypes()
      contentManagerController.getCompanies()
    }, [])

    const companiesOptions = React.useMemo(
      () =>
        companies?.map((item) => ({
          name: item.id,
          value: item.name,
        })) ?? [],
      [companies]
    )

    const contentTypeOptions = React.useMemo(
      () =>
        contentTypes?.map((item) => ({
          name: item.id,
          value: item.name,
        })) ?? [],
      [contentTypes]
    )

    return (
      <BaseModal
        className={styles.root}
        title={
          <ModalTitle
            titleText={
              <div className={styles.titleContainer}>
                {icon && <DocumentIcon icon={icon} />}
                {capitalizeFirstLetter(label)}
              </div>
            }
          />
        }
        containerClassName={styles.container}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button
                variant="outlined"
                size="medium"
                onClick={hideModal}
                disabled={loadingCreatedContentSource}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={loadingCreatedContentSource || !formik.isValid}
                size="medium"
                after={loadingCreatedContentSource && <Loader />}
                onClick={formik.submitForm}
              >
                Connect
              </Button>
            </div>
          }
        >
          <FormikProvider value={formik}>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
              <div className={styles.inputsWrapper}>
                <div className={styles.inputContainer}>
                  <Text variant="body2">Manufacturer *</Text>
                  <Select
                    options={companiesOptions}
                    placeholder="Select Manufacturer"
                    value={formik.values.companyId ?? null}
                    onChange={(companyId) => {
                      const selectedCompany = companies.find(
                        (company) => company.id === companyId
                      )
                      formik.setFieldValue(
                        "companyId",
                        selectedCompany?.id || ""
                      )
                      formik.setFieldTouched("companyId", true)
                    }}
                    withError={
                      !!(formik.touched.companyId && !formik.values.companyId)
                    }
                  />
                </div>
                {isWebsite ? (
                  <>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">Website Name *</Text>
                      <FormTextInput
                        name="websiteName"
                        key="websiteName"
                        placeholder="Website Name"
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">{domainHeading} *</Text>
                      <FormTextInput
                        name="domain"
                        key="domain"
                        placeholder={domainPlaceholder}
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">Child URLs(Comma separated)</Text>
                      <FormTextInput
                        name="childUrls"
                        key="childUrls"
                        placeholder="/child/url1, /child/url2"
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">Keywords</Text>
                      <FormTextInput
                        name="keywords"
                        key="keywords"
                        placeholder="Enter keywords"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">Connector Name *</Text>
                      <FormTextInput
                        name="name"
                        key="name"
                        placeholder="Enter name"
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">{domainHeading} *</Text>
                      <FormTextInput
                        name="domain"
                        key="domain"
                        placeholder={domainPlaceholder}
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <Text variant="body2">Content Type</Text>
                      <Select
                        options={contentTypeOptions}
                        placeholder="Select content type"
                        value={formik.values.contentType}
                        onChange={(contentTypeId) =>
                          formik.setFieldValue(
                            "contentType",
                            contentTypes.find(
                              (contentType) => contentType.id === contentTypeId
                            )?.id,
                            true
                          )
                        }
                      />
                    </div>

                    <div className={styles.inputContainer}>
                      <Text variant="body2">Client ID *</Text>
                      <FormTextInput
                        name="clientId"
                        key="clientId"
                        placeholder="Enter client ID"
                      />
                    </div>
                    {sourceName !== "sharepoint_online" && (
                      <div className={styles.inputContainer}>
                        <Text variant="body2">Client Secret *</Text>
                        <FormTextInput
                          name="clientSecret"
                          key="clientSecret"
                          placeholder="Enter client secret"
                          type={showSecret ? "text" : "password"}
                          after={
                            <Icon
                              name={showSecret ? "eye-close" : "eye"}
                              className={styles.eyeIcon}
                              onClick={() => setShowSecret(!showSecret)}
                            />
                          }
                        />
                      </div>
                    )}

                    {sourceName === "sharepoint_online" && (
                      <SharePointConfig formik={formik} />
                    )}
                  </>
                )}
              </div>
            </form>
          </FormikProvider>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default ContentManagerConfigFormModal
