import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useAlert } from "react-alert"
import { useNavigate } from "react-router-dom"

import Text from "@components/ui/Typography/Text"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { useController, useStore } from "@store"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { QueryHistoryData } from "@framework/types/analytics"
import { countSuffix } from "@utils/numberUtils"
import { copyToClipboard } from "@utils/clipboard"
import mainRoutes from "@root/main.routes"
import Loader from "@components/ui/Loader/BarLoader"
import { useSolution } from "@pages/launch-solutions/ActiveSolutionContext"

import styles from "./QuestionCard.module.sass"

export type QuestionCardProps = {
  question: QueryHistoryData
  onExpertsAdded: () => void
}

const QuestionCard: React.FC<QuestionCardProps> = observer(
  ({ question, onExpertsAdded }) => {
    const navigate = useNavigate()
    const modal = useModal(ModalsTypes.CONFIRM_MODAL)

    const { solution } = useSolution()

    const {
      solutionsStore,
      factFinderSolutionStore: { searchFlowStore },
      contentManagerStore: { companies },
      knowledgeStore: { assignQuestionToExpertDirectly },
      restrictionsStore: access,
    } = useStore()

    const { factFinderSolutionController } = useController()

    const { avatar } = useActiveAvatar()
    const alert = useAlert()
    const [copyLoading, setCopyLoading] = useState(false)

    const { showModal: showDirectAssignModal } = useModal(
      ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL
    )
    const askRecentQuestion = (query: string) => {
      solutionsStore.showRecentActivity = false
      searchFlowStore.reset()
      factFinderSolutionController.clearHighLights()
      if (solution?.id) {
        factFinderSolutionController.search(
          query,
          avatar,
          solution.id,
          solutionsStore.appliedFilters,
          companies
        )
      }
    }
    const handleClick = (query: string) => {
      if (!searchFlowStore?.flowSequence?.length) askRecentQuestion(query)
      else
        modal.showModal({
          message: (
            <>
              Are you sure you want to continue?
              <br /> it will reset current search results!
            </>
          ),
          confirm: "positive",
          onConfirm: () => askRecentQuestion(query),
        })
    }

    const onCopy = async () => {
      setCopyLoading(true)
      const result = await assignQuestionToExpertDirectly(
        avatar.name,
        avatar.id,
        question.question,
        [],
        undefined,
        "",
        question.summary
      )
      if (result.status === "FAILED") {
        alert.error(result.message)
        return false
      }
      const questionId = result.data[0].id
      if (!questionId) {
        alert.error("Failed to generate link")
        return false
      }
      copyToClipboard(
        `${window.location.origin}${mainRoutes.question(questionId)}`
      )
      alert.success(`Question link copied to clipboard`)
      setCopyLoading(false)
      return true
    }

    const handleImprovementClick = async () => {
      if (access.canSuggestImprovements) {
        navigate(mainRoutes.question(question.expertQuestionId))
      }
    }

    const handleReminderClick = () => {
      showDirectAssignModal({
        title: "Post to Expert",
        avatarId: avatar.id,
        questions: [question.question],
        questionId: question.expertQuestionId,
        postedExperts:
          question.experts?.map((item) => ({
            ...item,
            isExpertVerified: !!question.expertAnswers?.some(
              (e) => e.id === item.id
            ),
          })) || [],
        onSubmit: async (expertIds, questionNote) => {
          const result = await assignQuestionToExpertDirectly(
            avatar.name,
            avatar.id,
            question.question,
            expertIds,
            undefined,
            questionNote,
            question.summary
          )
          if (result.status === "FAILED") {
            alert.error(result.message)
            return false
          }
          const totalExperts = expertIds.length
          alert.success(
            `Question successfully assigned to expert${countSuffix(
              totalExperts
            )}`
          )
          onExpertsAdded()
          return true
        },
        onCopy,
      })
    }

    const { totalAnswered, expertQuestionId } = question

    const isExpertAssigned = !!expertQuestionId
    const isExpertVerified = isExpertAssigned && parseInt(totalAnswered, 10) > 0
    const statusText = isExpertVerified
      ? "Expert Verified"
      : isExpertAssigned
      ? "Pending"
      : ""

    return (
      <div className={styles.root}>
        {isExpertAssigned && access.canViewQuestions && (
          <List overflow="hidden" direction="row" gutter="4">
            <Icon
              color={isExpertVerified ? "green" : "gold"}
              name={isExpertVerified ? "shield-check-fill" : "timer-fill"}
            />
            <Text variant="caption2">{statusText}</Text>
          </List>
        )}
        <Text
          tabIndex={0}
          onKeyPress={() => {}}
          role="button"
          key={question.id}
          onClick={() => handleClick(question.question)}
          variant="caption1"
          className={styles.question}
        >
          {question.question}
        </Text>
        <div className={styles.footer}>
          <Text variant="caption2" color="text50Color">
            {moment(question.createdAt).fromNow()}
          </Text>
          {isExpertAssigned && !isExpertVerified && access.canViewQuestions && (
            <div className={styles.footerButtons}>
              {access.canPostToExpert && (
                <>
                  <Button
                    after={copyLoading && <Loader />}
                    disabled={copyLoading}
                    onClick={onCopy}
                    size="tiny"
                    color="secondary"
                    before={<Icon name="link" />}
                  >
                    Copy Link
                  </Button>

                  <Button
                    size="tiny"
                    color="primary"
                    onClick={handleReminderClick}
                  >
                    Send Reminder
                  </Button>
                </>
              )}
              {access.canSuggestImprovements && (
                <Button
                  size="tiny"
                  color="primary"
                  onClick={handleImprovementClick}
                >
                  Improve This Answer
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default QuestionCard
