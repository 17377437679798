import React from "react"
import { observer } from "mobx-react-lite"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { useController, useStore } from "@store"
import mainRoutes from "@root/main.routes"
import {
  TableOrderContext,
  useTableOrder,
} from "@components/ui/BaseTable/TableOrderContext"
import { SortBy } from "@framework/types/common"

import ManufacturerTable from "./components/ManufacturerTable"

import styles from "./Manufacturer.module.sass"

const ManufacturerPage: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()
  const modal = useModal(ModalsTypes.ADD_MANUFACTURER_MODAL)

  const { companyController } = useController()

  const [orderBy, setOrder] = React.useState<SortBy | null>({
    name: "name",
    direction: true,
  })

  const orderContext = useTableOrder({ orderBy, onOrderChange: setOrder })

  //  uncomment when BE search available
  //   const [searchProps, searchContext] = useSearch()

  React.useEffect(() => {
    companyController.fetchCompanies(orderBy)
  }, [orderBy])

  return (
    <TableOrderContext.Provider value={orderContext}>
      <MainLayout noPadding>
        <Container>
          <div className={styles.root}>
            <EntityPageHeaderLayout
              className={styles.header}
              left={
                <BackButton to={mainRoutes.library()}>Manufacturer</BackButton>
              }
              right={
                access.canAddManufacturer && (
                  <Button
                    onClick={() => modal.showModal({})}
                    color="primary"
                    size="big"
                  >
                    Add Manufacturer
                  </Button>
                )
              }
            >
              {/* uncomment when BE search available */}
              {/* <TextInput
              before={<Icon name="search" />}
              placeholder="Search"
              after={
                !!searchProps.value && (
                  <Icon
                    name="cross"
                    onClick={() => searchContext.setQuery("")}
                  />
                )
              }
            /> */}
            </EntityPageHeaderLayout>
            <ManufacturerTable />
          </div>
        </Container>
      </MainLayout>
    </TableOrderContext.Provider>
  )
})

export default ManufacturerPage
