import { SortByParams } from "@services/common/types"
import { V2_Products } from "@store/product/product.store"

import { Company } from "./company"

export interface ProductProperty {
  id: string
  name: string
  value: string
  properties?: ProductProperty[]
  type: "string" | "list"
  createdAt: string
}

export interface ProductDocument {
  id: string
  name: string
  url: string
  createdAt: string
}

export interface Attribute {
  id?: string
  name: string
  value: string
  unit?: string
  type?: string
}

export interface Product {
  id: string
  name: string
  summary: string
  companyId: string
  properties: ProductProperty[]
  documents: ProductDocument[]
  createdAt: string
  description?: string
}

export interface CategoryChild {
  id: string
  name: string
  child?: CategoryChild
}

export type LinkProducts = {
  id: string
  relatedProduct: V2_Products
  relationshipType: string
  note: string
}

export interface ProductDetails {
  id: string
  name: string
  description: string
  notes: string
  categories: CategoryChild[]
  company: Company
  attributes?: Attribute[]
  linkedProducts?: LinkProducts[]
}

export interface PatchProductRequest {
  name: string
  description: string
  notes: string
  companyId: string
  categoryIds: string[]
  addedDocumentIds: string[]
  deletedDocumentIds: string[]
}

export interface ListResponse<T> {
  data: T[]
  meta?: {
    total: number
    nextPageToken?: string
  }
}

export interface ProductQueryResponse {
  products: Product[]
  nextPageToken?: string
}

export interface ProductQueryRequest {
  avatarId: string
  name?: string
  pageSize?: number
  pageNumber?: number
  pageToken?: string
  filter?: string
}

export type GetComparisonReportParams = {
  targetCompanyId: string
  businessUnit?: string
  productIds: string[]
  requirementFile: File
  baseCompanyId: string
  requirementText?: string
}

export type ProductListQueryRequest = {
  filters: {
    search?: string
    companyIds?: string[]
    categoryIds?: string[]
    include?: boolean
  }
  pagination: {
    page: number
    pageSize?: number
  }
} & (SortByParams | {})

export type ProductListResponsePaginationMetaData = {
  page: number
  pageSize: number
  totalItems: number
  totalPages: number
}

export type AddProductRequest = {
  name: string
  description?: string
  categoryIds: string[]
  companyId: string
}

export enum ProductRelationshipType {
  Competitor = "Competitor",
  Complimentary = "Complimentary",
  PrecursorDerivative = "Precursor/Derivative",
  SubstituteAlternative = "Substitute/Alternative",
  Quality = "Quality",
  MarketSegment = "Market Segment",
  Regulatory = "Regulatory",
  ProcessIntegration = "Process Integration",
  TechnologyRevolution = "Technology Revolution",
  SynergisticPerformance = "Synergistic Performance",
  SafetyHandling = "Safety/Handling",
  Lifecycle = "Lifecycle",
  SupplyChain = "Supply Chain",
  Application = "Application",
  Pricing = "Pricing",
}

export type LinkProductsData = {
  id?: string
  productId?: string
  relatedProductId: string
  relationshipType: string
  note: string
}

export type LinkProductsResponseData = {
  data: LinkProductsData[]
}

export type LinkContent = { connectorName: string; id: string }
