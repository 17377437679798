import { makeAutoObservable } from "mobx"
import groupBy from "lodash/groupBy"
import omit from "lodash/omit"

import RootStore from "@store/RootStore"
import subscriptionService from "@services/subscription.service"
import defaultErrorHandler from "@store/utils/error-handlers"
import { SubscriptionsData } from "@framework/types/subscription"

import SubscriptionStore from "./subscription.store"

export class SubscriptionController {
  subscriptionStore: SubscriptionStore

  constructor(injections: RootStore) {
    this.subscriptionStore = injections.subscriptionStore
    makeAutoObservable(this)
  }

  getSubscription = async () => {
    const store = this.subscriptionStore

    try {
      store.isLoading = true
      store.error = null

      const res = await subscriptionService.getSubscription()
      if (res?.data?.data) {
        store.storeData(res.data.data)
      } else {
        store.error = "Subscription not found"
      }
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  getAvailableOptions = async () => {
    const store = this.subscriptionStore

    try {
      store.isLoading = true
      store.error = null

      const res = await subscriptionService.getAvailableOptions()
      if (res?.data?.data) {
        store.storeAvailableOptions(res.data.data)
      } else {
        store.error = "Subscription options not found"
      }
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  init = async () => {
    const store = this.subscriptionStore
    try {
      store.errorLoadingData = false
      store.setLoading()
      store.setError()
      await this.getAvailableOptions()
      await this.getSubscription()
      store.editedSubscriptionData = store.data
      const usageData = await subscriptionService.getMonthlySubscriptionUsage()
      store.subscriptionUsage = usageData?.data?.data
      return store.data
    } catch (error) {
      store.setError(defaultErrorHandler(error))
      store.errorLoadingData = true
      return store.error
    } finally {
      store.setLoading(false)
    }
  }

  setEditable = (value: boolean) => {
    this.subscriptionStore.isEditable = value
  }

  updateEditData = (key: string, value: any) => {
    const store = this.subscriptionStore
    store.editedSubscriptionData = {
      ...store.editedSubscriptionData,
      [key]: value,
    }
  }

  updateSubscription = async (data: SubscriptionsData) => {
    try {
      this.subscriptionStore.setLoading()
      this.subscriptionStore.setError()
      const newData = data
      newData.connectors = [
        ...new Set([
          ...(data.connectorsBasic || []),
          ...(data.connectorsPremium || []),
          ...(data.connectorsCustom || []),
        ]),
      ]
      const response = await subscriptionService.updateSubscription(
        omit(
          newData,
          "apiQuotaConsumed",
          "createdAt",
          "updatedAt",
          "id",
          "connectorsBasic",
          "connectorsPremium",
          "connectorsCustom"
        )
      )
      this.subscriptionStore.storeData(response.data.data)
      return response
    } catch (error) {
      this.subscriptionStore.setError(
        defaultErrorHandler(error, "Failed to update subscription")
      )
      return { error: this.subscriptionStore.error }
    } finally {
      this.subscriptionStore.setLoading(false)
    }
  }

  upgradeSubscription = async (description: string) => {
    try {
      this.subscriptionStore.setLoading()
      this.subscriptionStore.setError()
      const response = await subscriptionService.upgradeSubscription(
        description
      )

      return response
    } catch (error) {
      this.subscriptionStore.setError(
        defaultErrorHandler(
          error,
          "Failed to send request to upgrade subscription"
        )
      )
      return { error: this.subscriptionStore.error, data: null }
    } finally {
      this.subscriptionStore.setLoading(false)
    }
  }

  addAvailableOption = async (name: string, groupName: string) => {
    try {
      this.subscriptionStore.setError()
      const res = await subscriptionService.addAvailableOption({
        name,
        groupName,
      })
      if (res.data.data) {
        const store = this.subscriptionStore
        const oldOptions = store.availableOption[groupName]
        const newOptions = [...oldOptions, res.data.data]
        store.availableOption[groupName] = newOptions
        return res.data.data
      }
    } catch (error) {
      this.subscriptionStore.setError(
        defaultErrorHandler(error, "Failed to add subscription option")
      )
    } finally {
      this.subscriptionStore.setLoading(false)
    }
    return null
  }
}

export default SubscriptionController
