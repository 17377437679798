import { AxiosResponse } from "axios"

import {
  CompanyWithMetaInfo,
  CreateCompanyRequest,
  LinkCompanyRequest,
} from "@framework/types/company"

import HttpService from "./http.service"
import { SortByParams } from "./common/types"

class CompanyAPI extends HttpService {
  getCompanies = (
    options?: SortByParams
  ): Promise<AxiosResponse<CompanyWithMetaInfo[]>> => {
    return this.get("ts/v2/companies", true, options)
  }

  getTrialInstanceCompanies = (): Promise<
    AxiosResponse<CompanyWithMetaInfo[]>
  > => {
    return this.get("ts/v2/companies/public")
  }

  createCompany = (
    data: CreateCompanyRequest
  ): Promise<AxiosResponse<CompanyWithMetaInfo>> => {
    return this.post("ts/v2/companies", data)
  }

  updateCompany = (
    data: CreateCompanyRequest,
    id: string
  ): Promise<AxiosResponse<CompanyWithMetaInfo>> => {
    return this.patch(`ts/v2/companies/${id}`, data)
  }

  linkCompanies = (data: LinkCompanyRequest): Promise<AxiosResponse<any>> => {
    return this.post("ts/v2/companies/link", data)
  }
}

export default new CompanyAPI()
