import React, { useState } from "react"
import { useAlert } from "react-alert"

import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { QuestionFeedbackType } from "@framework/constants/search-results"
import { useController } from "@store/index"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Rating from "@components/ui/Rating/Rating"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"
import { useSolution } from "@pages/launch-solutions/ActiveSolutionContext"

import styles from "./StarRatings.module.sass"

const SUCCESS_MESSAGE_TITLE = "You've successfully voted"
const SUCCESS_MESSAGE_SUBTITLE = "Thank you for your feedback!"

type StarRatingsProps = {
  className?: string
  disabled?: boolean
  message?: string
}

const StarRatings: React.FC<StarRatingsProps> = ({
  disabled = false,
  message = "",
}) => {
  const alert = useAlert()
  const modal = useModal(ModalsTypes.ANSWER_FEEDBACK_MODAL)

  const { solution } = useSolution()

  const { searchEntityBlock, searchEntity } = useSearchBlock()
  const { factFinderSolutionController } = useController()

  if (!(searchEntityBlock instanceof SearchSummaryBlockStore))
    throw new Error(
      "This answers data store does not support voting feature yet"
    )

  const [rating, setRating] = useState(3)

  const handleStarClick = (starIndex: number) => {
    setRating(starIndex)
    handleFeedback(starIndex)
  }

  const handleAlert = (error: string | null, title?: string) => {
    if (error) {
      alert.error(error)
      return
    }
    alert.success(
      <>
        {title ?? SUCCESS_MESSAGE_TITLE}
        <Text variant="h5" color="text50Color">
          {SUCCESS_MESSAGE_SUBTITLE}
        </Text>
      </>
    )
  }

  const handleFeedback = async (ratingVal: number) => {
    const rawAnswer = searchEntityBlock.serialize()

    if (rawAnswer == null) return

    const feedback =
      ratingVal > 3
        ? QuestionFeedbackType.POSITIVE
        : ratingVal === 3
        ? QuestionFeedbackType.NEUTRAL
        : QuestionFeedbackType.NEGATIVE

    modal.showModal({
      onSubmit: async (rating: number, message: string) => {
        const error = await factFinderSolutionController.voteForQuestion(
          searchEntityBlock,
          searchEntity.filter.searchAvatar,
          searchEntity.filter.searchAvatarId,
          searchEntity.filter.searchQuery,
          feedback,
          rawAnswer,
          message,
          searchEntityBlock.queryType,
          solution.id,
          rating
        )

        handleAlert(error)

        setRating(rating)

        modal.hideModal()
      },
      rating: ratingVal,
      onHide: () => {
        setRating(3)
      },
    })
  }

  return (
    <Tooltip placement="bottom-end" color="primary">
      <TooltipTrigger>
        <Rating
          value={rating}
          {...(!disabled && { onChange: handleStarClick })}
        />
      </TooltipTrigger>
      <TooltipContent maxWidth="none" className={styles.tooltip}>
        <div className={styles.defaultRating}>
          <Text variant="h4">
            {disabled ? `You rated ${rating}` : "Default rating 3"}
          </Text>
          <Icon name="star-filled" color="gold" />
        </div>

        {disabled ? (
          message && <Text variant="h6">{message}</Text>
        ) : (
          <Text variant="h6">
            You can also write a feedback by clicking on the star ratings
          </Text>
        )}
      </TooltipContent>
    </Tooltip>
  )
}

export default StarRatings
